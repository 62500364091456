import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';
import './WaterAllocation.css';

// https://shancarter.github.io/mr-data-converter/
const rows = [
  {"no":1,"month":"Jan","alert":"-","warning":"-","danger":"-"},
  {"no":2,"month":"Feb","alert":"-","warning":"-","danger":"-"},
  {"no":3,"month":"Mar","alert":"-","warning":"-","danger":"-"},
  {"no":4,"month":"Apr","alert":"3","warning":"-","danger":"-"},
  {"no":5,"month":"May","alert":"31","warning":"-","danger":"-"},
  {"no":6,"month":"Jun","alert":"30","warning":"-","danger":"-"},
  {"no":7,"month":"Jul","alert":"31","warning":"-","danger":"-"},
  {"no":8,"month":"Aug","alert":"29","warning":"-","danger":"-"},
  {"no":9,"month":"Sep","alert":"-","warning":"-","danger":"-"},
  {"no":10,"month":"Oct","alert":"-","warning":"-","danger":"-"},
  {"no":11,"month":"Nov","alert":"-","warning":"-","danger":"-"},
  {"no":12,"month":"Dec","alert":"-","warning":"-","danger":"-"}
]


function preventDefault(event) {
  event.preventDefault();
}

export default function WaterAllocationIrrigation() {
  return (
    <React.Fragment>
      <Title>Number of Times Dam Water Level Reaches Threshold Levels </Title>
      <Table className="water-allocation-table" size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}} rowSpan={2}>No</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}} rowSpan={2}>Month</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}} colSpan={3}>Number of Days Dam WL Reaches Threshold Levels (Day)</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Alert</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Warning</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Danger</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="center">{row.no}</TableCell>
              <TableCell align="center">{row.month}</TableCell>
              <TableCell align="center">{row.alert}</TableCell>
              <TableCell align="center">{row.warning}</TableCell>
              <TableCell align="center">{row.danger}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold'}}>Total</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>124</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>0</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>0</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
