import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';

// Generate Order Data
function createData(id, wtp, capacity, riverlen, si, ecoi, envi, wa, ai, wp) {
  return { id, wtp, capacity, riverlen, si, ecoi, envi, wa, ai, wp};
}

const rows = [
  createData(0, 'Sg Batu', '113.7', '97.5', '0.92', '0.15', '1.00', '0.02', '0.52', '3'), 
  createData(1, 'Kepong', '4.5', '91.6', '0.88', '0.01', '0.94', '0.51', '0.55', '3'), 
  createData(2, 'Sg Gombak', '22.5', '93.7', '0.93', '0.07', '0.96', '0.10', '0.51', '3'), 
  createData(3, 'Sg Rumput', '2.3', '97.6', '0.88', '0.01', '1.00', '1.00', '0.66', '2'), 
  createData(4, 'Wangsa Maju', '22.5', '92.2', '0.94', '0.14', '0.94', '0.10', '0.53', '3'), 
  createData(5, 'Bukit Nanas', '145', '78.1', '1.00', '1.00', '0.80', '0.02', '0.78', '1'), 
  createData(6, 'Ampang', '18', '92.2', '0.88', '0.12', '0.94', '0.13', '0.51', '3'), 
  createData(7, 'North Hummock', '22.5', '21.6', '0.54', '0.29', '0.22', '0.10', '0.35', '4'), 
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {
  return (
    <React.Fragment>
      <Title>Water Priority Categorization</Title>
      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} sx={{fontWeight: 'bold'}}>WTP</TableCell>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold'}}>Weight</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>0.4</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>0.3</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>0.1</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>0.2</TableCell>
            <TableCell rowSpan={2} align="center" sx={{fontWeight: 'bold'}}>Aggregated Index, I</TableCell>
            <TableCell rowSpan={2} align="center" sx={{fontWeight: 'bold'}}>Water Priority</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Design Capacity (MLD)</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Downstream River Length, L (km)</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Social Index, I<sub>soc</sub></TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Economy Index, I<sub>ec</sub></TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Environment Index, I<sub>env</sub></TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Water Availability, I<sub>wri</sub></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.wtp}</TableCell>
              <TableCell>{row.capacity}</TableCell>
              <TableCell>{row.riverlen}</TableCell>
              <TableCell>{row.si}</TableCell>
              <TableCell>{row.ecoi}</TableCell>
              <TableCell>{row.envi}</TableCell>
              <TableCell>{row.wa}</TableCell>
              <TableCell>{row.ai}</TableCell>
              <TableCell>{row.wp}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>Total</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>351.0</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
