import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';
import './WaterAllocation.css';

// https://shancarter.github.io/mr-data-converter/
const rows = [
  
  {"property":"Main Dam Level","value":"RL +35.7 m"},
  {"property":"Maximum Dam Water Level","value":"RL +29.1 m"},
  {"property":"Maximum Reservoir Storage","value":"40 MCM"},
  {"property":"Dam Catchment Area","value":"191 km2"},
  {"property":"Maximum Dam Water Level (New)","value":"RL +31.0 m"},
  {"property":"Maximum Reservoir Storage (New)","value":"60 MCM"},
  {"property":"Water Level 1:100 year ARI","value":"RL +32.2 m"},
  {"property":"Maximum Discharge Western Spillway Q100","value":"143 m3/s"}
]


function preventDefault(event) {
  event.preventDefault();
}

export default function DamPropertiesTable() {
  return (
    <React.Fragment>
      <Title>Timah Tasoh Dam Properties</Title>
      <Table className="dam-properties-table" size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow sx={{backgroundColor: '#ebebeb'}}>
            <TableCell align="center" sx={{fontWeight: 'bold'}} colSpan={2}>Dam Properties</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="center">{row.property}</TableCell>
              <TableCell align="center">{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <i style={{fontSize:"80%"}}>Source: Department of Irrigation and Drainage Perlis, 2022</i>
    </React.Fragment>
  );
}
