import React, { Component } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
const BaseGoogleMap = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={13}
    defaultCenter={{ lat: 6.579785447845355,lng: 100.23062949718411,}}
  >
    {props.markers}
  </GoogleMap>
))

export default BaseGoogleMap;