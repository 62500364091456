import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';

// Generate Data
function createData(no, plan, source, capacity, abstraction, production, losses_mld, losses_percent) {
  return {no, plan, source, capacity, abstraction, production, losses_mld, losses_percent};
}

const arows = [
  createData('1', 'Arau Fasa I, II, III WTP', 'MADA Northern Canal & Groundwater', '52', '47.19', '46.21', '0.99', '2.09%'),
  createData('2', 'Arau Fasa IV Kg. Sena WTP', 'MADA Northern Canal', '123', '124.73', '123.90', '0.83', '0.67%'),
  createData('3', 'Timah Tasoh WTP', 'Timah Tasoh Dam', '36', '70.80', '66.25', '4.55', '6.42%'),
];

// const brows = [
//   createData(1, 'LRA Kg Bingkor (ABJV)', 'Sg Bayayo', '20', '19.1', '18.7', '0.4', '2.1'),
//   createData(2, 'LRA Kg Bunsit', 'Sg Bayayo', '70', '49.1', '44.2', '4.9', '9.9'),
//   createData(3, 'LRA Kg Keningau', 'Sg Liawan', '5', '5.6', '5.3', '0.3', '4.5'),
//   createData(4, 'LRA Kg Agudon', 'Sg Pampang', '8', '12.7', '9.5', '3.2', '24.9'),
//   createData(5, 'LRA Pekan Sook I', 'Sg Sook', '1.5', '1.2', '1.2', '0.0', '0.7'),
//   createData(6, 'LRA Sook II', 'Sg Sook', '2', '1.7', '1.5', '0.2', '11.3'),
//   createData('', '', '', '', '', '', '', ''),
// ];

// const crows = [
//   createData(1, 'LRA Kemabong', 'Sg Padas', '6', '5.5', '5.1', '0.4', '6.6'),
//   createData(2, 'LRA Senagang I & II', 'Sg Masalog', '12', '13.4', '12.7', '0.7', '5.4'),
//   createData(3, 'LRA Pekan Tenom', 'Sg Padas', '4.5', '3.1', '2.9', '0.2', '6.1'),
//   createData('', '', '', '', '', '', '', ''),
// ];

// const drows = [
//   createData(1, 'LRA Montenior (ABJV) Beaufort 1', 'Sg Padas', '30', '26.9', '24.7', '2.2', '8.1'),
//   createData(2, 'LRA Montenior (ABJV) Beaufort 2', 'Sg Padas', '30', '29.3', '28', '1.3', '4.4'),
//   createData(3, 'LRA EWSS Limbawang', 'Sg Padas', '20', '17.3', '16', '1.3', '7.5'),
//   createData(4, 'LRA Lawa Gadong', 'Sg Padas', '76', '79.4', '74.8', '4.6', '5.8'),
//   createData('', '', '', '', '', '', '', ''),
// ];


function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {
  return (
    <React.Fragment>
      <Title>b. Water Auditing - Water Treatment Plants</Title>
      <Title>Year 2021 Water Auditing</Title>
      <Table className="water-allocation-table" size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} sx={{fontWeight: 'bold'}}>No.</TableCell>
            <TableCell rowSpan={2} sx={{fontWeight: 'bold'}}>Water Treatment Plant (WTP)</TableCell>
            <TableCell rowSpan={2} sx={{fontWeight: 'bold'}}>Water Source</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>Design Capacity</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>Abstraction</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>Production</TableCell>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold'}}>Losses</TableCell>
            {/* <TableCell>Payment Method</TableCell>
            <TableCell align="right">Sale Amount</TableCell> */}
          </TableRow>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>MLD</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>MLD</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>MLD</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>MLD</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>%</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {arows.map((arow) => (
            <TableRow key={arow.no}>
              <TableCell>{arow.no}</TableCell>
              <TableCell>{arow.plan}</TableCell>
              <TableCell>{arow.source}</TableCell>
              <TableCell>{arow.capacity}</TableCell>
              <TableCell>{arow.abstraction}</TableCell>
              <TableCell>{arow.production}</TableCell>
              <TableCell>{arow.losses_mld}</TableCell>
              <TableCell>{arow.losses_percent}</TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell colSpan={3} align="center" sx={{fontWeight: 'bold'}}>Total</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>211.00</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>242.72</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>236.36</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>6.37</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>9.18%</TableCell>
          </TableRow>

          {/* <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>B</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>Keningau</TableCell>
          </TableRow>
          {brows.map((brow) => (
            <TableRow key={brow.no}>
              <TableCell>{brow.no}</TableCell>
              <TableCell>{brow.plan}</TableCell>
              <TableCell>{brow.source}</TableCell>
              <TableCell>{brow.capacity}</TableCell>
              <TableCell>{brow.abstraction}</TableCell>
              <TableCell>{brow.production}</TableCell>
              <TableCell>{brow.losses_mld}</TableCell>
              <TableCell>{brow.losses_percent}</TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>C</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>Tenom</TableCell>
          </TableRow>
          {crows.map((crow) => (
            <TableRow key={crow.no}>
              <TableCell>{crow.no}</TableCell>
              <TableCell>{crow.plan}</TableCell>
              <TableCell>{crow.source}</TableCell>
              <TableCell>{crow.capacity}</TableCell>
              <TableCell>{crow.abstraction}</TableCell>
              <TableCell>{crow.production}</TableCell>
              <TableCell>{crow.losses_mld}</TableCell>
              <TableCell>{crow.losses_percent}</TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>D</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>Beaufort</TableCell>
          </TableRow>
          {drows.map((drow) => (
            <TableRow key={drow.no}>
              <TableCell>{drow.no}</TableCell>
              <TableCell>{drow.plan}</TableCell>
              <TableCell>{drow.source}</TableCell>
              <TableCell>{drow.capacity}</TableCell>
              <TableCell>{drow.abstraction}</TableCell>
              <TableCell>{drow.production}</TableCell>
              <TableCell>{drow.losses_mld}</TableCell>
              <TableCell>{drow.losses_percent}</TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell></TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>Total</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>298.0</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>276.6</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>253.7</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>22.9</TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
