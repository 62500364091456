import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';

// https://shancarter.github.io/mr-data-converter/
const rows = [
  {"no":1,"water_users":"Arau Phase I, II, III WTP","water_source":"MADA Northern Canal & Groundwater","average":52,"percentage":"24.6%"},
  {"no":2,"water_users":"Arau Phase IV Kg. Sena WTP","water_source":"MADA Northern Canal","average":123,"percentage":"58.3%"},
  {"no":3,"water_users":"Timah Tasoh WTP","water_source":"Timah Tasoh Dam","average":36,"percentage":"17.1%"}
]


function preventDefault(event) {
  event.preventDefault();
}

export default function WaterAllocationWtp() {
  return (
    <React.Fragment>
      <Title>Table 1 - WTP</Title>
      <Table className="water-allocation-table" size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
        <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}} rowSpan={2}>No</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}} rowSpan={2}>Water User</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}} rowSpan={2}>Water Source</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}} colSpan={2}>Water Usage</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Average (MLD)</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Percentage (%)</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="center">{row.no}</TableCell>
              <TableCell align="center">{row.water_users}</TableCell>
              <TableCell align="center">{row.water_source}</TableCell>
              <TableCell align="center">{row.average}</TableCell>
              <TableCell align="center">{row.percentage}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={3} align="center" sx={{fontWeight: 'bold'}}>Total</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>211.0</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>100</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
