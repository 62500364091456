import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';
import './WaterAllocation.css';

// https://shancarter.github.io/mr-data-converter/
const rows = [
  {"no":1,"water_users":"Alor Sena","water_source":"Sungai Santan","average":24.5,"percentage":"7.7%"},
  {"no":2,"water_users":"Semadong (ETT)","water_source":"Timah Tasoh Dam","average":4.9,"percentage":"1.5%"},
  {"no":3,"water_users":"Semadong (Skim)","water_source":"Timah Tasoh Dam","average":4.9,"percentage":"1.5%"},
  {"no":4,"water_users":"Bukit Tau","water_source":"Kolam Takungan","average":12.2,"percentage":"3.8%"},
  {"no":5,"water_users":"Kampong Belukar","water_source":"Sungai Korok","average":7.3,"percentage":"2.3%"},
  {"no":6,"water_users":"Sungai Jarum","water_source":"Sungai Jarum","average":4.9,"percentage":"1.5%"},
  {"no":7,"water_users":"Abi Kurung Batang","water_source":"Groundwater","average":4.9,"percentage":"1.5%"},
  {"no":8,"water_users":"Sungai Jernih","water_source":"Sungai Jernih","average":4.9,"percentage":"1.5%"},
  {"no":9,"water_users":"Sg. Gial","water_source":"NA","average":48.9,"percentage":"15.4%"},
  {"no":10,"water_users":"Sg. Jejawi / T1","water_source":"NA","average":24.5,"percentage":"7.7%"},
  {"no":11,"water_users":"Sg. Jejawi / T3","water_source":"NA","average":24.5,"percentage":"7.7%"},
  {"no":12,"water_users":"Sg. Jejawi / T8","water_source":"NA","average":12.2,"percentage":"3.8%"},
  {"no":13,"water_users":"Sg. Seriab A28","water_source":"NA","average":12.2,"percentage":"3.8%"},
  {"no":14,"water_users":"Sg. Seriab A25","water_source":"NA","average":12.2,"percentage":"3.8%"},
  {"no":15,"water_users":"Tok Kuning / Wang Bintong","water_source":"NA","average":34.3,"percentage":"10.8%"},
  {"no":16,"water_users":"Sg. Kayang","water_source":"NA","average":85.6,"percentage":"26.9%"}
]


function preventDefault(event) {
  event.preventDefault();
}

export default function WaterAllocationIrrigation() {
  return (
    <React.Fragment>
      <Title>Table 2 - Irrigation</Title>
      <Table className="water-allocation-table" size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}} rowSpan={2}>No</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}} rowSpan={2}>Water User</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}} rowSpan={2}>Water Source</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}} colSpan={2}>Water Usage</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Average (MLD)</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Percentage (%)</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="center">{row.no}</TableCell>
              <TableCell align="center">{row.water_users}</TableCell>
              <TableCell align="center">{row.water_source}</TableCell>
              <TableCell align="center">{row.average}</TableCell>
              <TableCell align="center">{row.percentage}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={3} align="center" sx={{fontWeight: 'bold'}}>Total</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>318.1</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>100</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
