import React, { Component } from 'react';
import { waterDemandData } from './WaterDemandData';

// Documentation for Apache Echart options at https://echarts.apache.org/en/option.html
const chartDomId = "chart" + Math.random()
class WaterDemandChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    componentDidMount() {
        var chartDom = document.getElementById(chartDomId);
        this.echart = window.echarts.init(chartDom)
        this.formatData(waterDemandData)
    }
    formatData = (data) => {
        let xAxis = []
        let yAxisData = []
        let legends = []
        let series = []
        data.forEach(dataSet => {
            legends.push(dataSet.name)
            
            let minValue = null
            let maxValue = null
            let seriesData = {
                name: dataSet.name,
                symbolSize: 1,
                type: 'line',
                data: [],
                lineStyle: {
                    color: dataSet.lineColor || null,
                    type: dataSet.lineStyle || 'solid',
                    width: '3',
                },
                itemStyle: {...dataSet.itemStyle},
            }

            let xAxisData = {
                data: [],
                show: dataSet.xAxisShow !== false,
            }
            dataSet.timeSeries.forEach(timeValue => {
                xAxisData.data.push(timeValue[0])
                seriesData.data.push([timeValue[0], timeValue[1]])

                let value = timeValue[1]
                if (minValue === null) {
                    minValue = value
                }
                else {
                    if (value < minValue) {
                        minValue = value
                    }
                }
                if (maxValue === null) {
                    maxValue = value
                }
                else {
                    if (value > maxValue) {
                        maxValue = value
                    }
                }
            })

            xAxis.push(xAxisData)
            series.push(seriesData)

            yAxisData.push({
                ...dataSet.yAxis,
                min: Math.floor(minValue),
                max: Math.ceil(maxValue),
                type: 'value',
            })
        })

        let options = {
            tooltip: {
                trigger: 'axis',
            },
            title: {
                text: 'Arau I, II, III Intake WTP',
                subtext: ``,
                left: 'center',
                top: "20",
                textStyle: {
                    fontSize: '24',
                },
            },
            legend: {
                data: legends,
                textStyle: {
                    fontSize: '16',
                },
                right: '20',
                top: '15',
            },
            dataZoom: [
                {
                  start: 0,
                  type: "inside"
                },
                {
                  type: 'slider',
                  show: true,
                  xAxisIndex: 0,
                  filterMode: 'filter',
                  start: 0,
                  end: 100,
                  handleSize: 8
                },
                {
                  type: 'slider',
                  show: true,
                  yAxisIndex: 0,
                  filterMode: 'empty',
                  width: 12,
                  height: '70%',
                  handleSize: 8,
                  showDataShadow: true,
                  right: '30'
                }
            ],
            xAxis: xAxis,
            yAxis: yAxisData,
            series: series,
        }
        console.log(options)
        this.echart.setOption(options)
    }
    render() {
        return (
            <div>
                <div id={chartDomId} style={{width: "100%", height: "600px", backgroundColor: "white", border: "1px solid #c3c3c3"}}>

                </div>
            </div>
        );
    }
}

export default WaterDemandChart;