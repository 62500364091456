import logo from './logo.svg';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import Dashboard from './dashboard/Dashboard';

// class App extends React.Component {
//   render() {
//       return (
//           <div>
//             <h1>Foo</h1>
//             <Dashboard/>
//           </div>
//       );
//   }
// }

// render(<App />, window.document.getElementById('app'));

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
