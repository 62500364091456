import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';
import './WaterAllocation.css';

// https://shancarter.github.io/mr-data-converter/
const rows = [
  {"no":1,"lat":6.4334,"lng":100.1574,"id":"2PS 01","new_id":"1RPLS001","location":"Jambatan Kg. Tebing Tinggi","river":"PERLIS"},
  {"no":2,"lat":6.4819,"lng":100.2491,"id":"2PS 03","new_id":"1RPLS002","location":"Jambatan simpang empat Ngulang","river":"NGULANG"},
  {"no":3,"lat":6.5444,"lng":100.2916,"id":"2PS 04","new_id":"1RPLS003","location":"Kg. Batu Bertangkup","river":"SERAI"},
  {"no":4,"lat":6.5153,"lng":100.2663,"id":"2PS 05","new_id":"1RPLS004","location":"Jambatan selepas Kilang Fermpro, Beseri","river":"JERNIH"},
  {"no":5,"lat":6.5170,"lng":100.2662,"id":"2PS 06","new_id":"1RPLS005","location":"Dalam Kg. Siam, Kilang Fermpro","river":"JERNIH"},
  {"no":6,"lat":6.6260,"lng":100.2602,"id":"2PS 08","new_id":"1RPLS006","location":"Stesen Telemetri JPS Kg. Titi Tinggi Ulu","river":"JARUM"},
  {"no":7,"lat":6.6553,"lng":100.3059,"id":"2PS 09","new_id":"1RPLS007","location":"Jalan Kg. Kolam, Padang Besar","river":"KOK MAK"},
  {"no":8,"lat":6.6287,"lng":100.2036,"id":"2PS 10","new_id":"1RPLS008","location":"Jambatan berdekatan stns TELEMETRI JPS Sg. Pelarit","river":"PELARIT"},
  {"no":9,"lat":6.7013,"lng":100.1986,"id":"2PS 11","new_id":"1RPLS009","location":"Taman Negeri","river":"WANG KELIAN"},
  {"no":10,"lat":6.4048,"lng":100.3005,"id":"2PS 13","new_id":"1RPLS010","location":"Muka sauk Loji Rawatan Air Arau Fasa IV","river":"TERUSAN MADA"},
  {"no":11,"lat":6.3415,"lng":100.1893,"id":"2PS 14","new_id":"1RPLS011","location":"Muka sauk Loji Rawatan Air TTPC, Sg. Baru","river":"TERUSAN MADA"},
  {"no":12,"lat":6.4178,"lng":100.2713,"id":"NEW","new_id":"1RPLS012","location":"Kampung Banat, Guar Sanji,","river":"ARAU"},
  {"no":13,"lat":6.4541,"lng":100.2037,"id":"NEW","new_id":"1RPLS013","location":"Kompleks Kastam Negeri, Jalan Kampung Pondok,","river":"KOROK"},
  {"no":14,"lat":6.4505,"lng":100.2962,"id":"NEW","new_id":"1RPLS014","location":"Jalan Padang Siding, Pauh","river":"ARAU"},
  {"no":15,"lat":6.5623,"lng":100.2254,"id":"NEW","new_id":"1RPLS015","location":"Jalan Guar Jentik Beseri,","river":"EMPANGAN TIMAH TASOH"}
]


function preventDefault(event) {
  event.preventDefault();
}

export default function WaterAllocationIrrigation() {
  return (
    <React.Fragment>
      {/* <Title>Table 2 - Irrigation</Title> */}
      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>No</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Latitude</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Longtitude</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Station ID (2016)</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>New Station ID</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Location</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>River</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="center">{row.no}</TableCell>
              <TableCell align="center">{row.lat}</TableCell>
              <TableCell align="center">{row.lng}</TableCell>
              <TableCell align="center">{row.id}</TableCell>
              <TableCell align="center">{row.new_id}</TableCell>
              <TableCell align="center">{row.location}</TableCell>
              <TableCell align="center">{row.river}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
