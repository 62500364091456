import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BaseGoogleMap from './BaseGoogleMap';
import { Marker, InfoWindow } from "react-google-maps";
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");

class PerlisMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            markers: [],
            infoWindowOpen: null,
        }
    }
    componentDidMount() {
        this.loadMarkers()
    }
    componentDidUpdate(prevProps, prevState) {

    }
    componentWillUnmount() {

    }
    onMarkerClicked = () => {

    }
    onOpenInfoWindow = (markerId) => {
        this.setState({
            infoWindowOpen: markerId
        }, this.loadMarkers)
    }
    onToggleOpen = (markerId) => {
        console.log("onToggleOpen")
        this.setState({
            infoWindowOpen: this.state.infoWindowOpen !== null ? null : markerId
        }, this.loadMarkers)
    }
    loadMarkers = () => {
        this.setState({
            markers: [this.timahDamMarker()]
        })
    }
    timahDamMarker = () => {
        const markerId = 0
        return <Marker 
            key={markerId}
            onClick={() => this.onToggleOpen(markerId)}
            onMouseOver={() => this.onOpenInfoWindow(markerId)}
            position={{ lat: 6.579785447845355, lng: 100.23062949718411, }}>
                {
                    this.state.infoWindowOpen == markerId && <InfoWindow onCloseClick={() => this.onToggleOpen(markerId)}>
                        <div style={{width: "250px"}}>
                            <div><h3>Timah Tasoh Dam (<span style={{color: "red"}}><strong>Danger</strong></span>)</h3></div>
                            <hr/>
                            <div>
                                <table style={{fontSize: "12pt"}}>
                                    <tbody>
                                        <tr>
                                            <td>Forecast Rainfall</td>
                                            <td> : </td>
                                            <td>0.2 mm</td>
                                        </tr>
                                        <tr>
                                            <td>Forecast Runoff</td>
                                            <td> : </td>
                                            <td>10 m<sup>3</sup>/s</td>
                                        </tr>
                                        <tr>
                                            <td>Water Level</td>
                                            <td> : </td>
                                            <td>27.3 m</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{marginTop: "8px", fontSize: "90%"}}>
                                    <i>Last updated at 14-Dec-2022 8am</i>
                                </div>
                                <hr/>
                                <div style={{textAlign: "right"}}>
                                    <img src={"/image/icon-graph.png"} style={{marginRight: "15px", width: "30px", height: "30px"}} />
                                    <img src={"/image/icon-table.png"} style={{width: "30px", height: "30px"}} />
                                </div>
                            </div>
                        </div>
                    </InfoWindow>
                }
        </Marker>
    }
    render() {
        return (
            <div>
                <BaseGoogleMap 
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAE7_mCahainCdUH098IVG1jVvJ97IqPzI&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `600px`, width: "100%" }} />}
                    containerElement={<div style={{ height: `600px`, width: "100%"}} />}
                    mapElement={<div style={{ height: `600px`, width: "100%" }} />}
                    markers={this.state.markers}
                />
            </div>
        );
    }
}

PerlisMap.propTypes = {

};

export default PerlisMap;