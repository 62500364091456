import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';

let id = -1
// Generate Order Data
// function createData(wtp, source, capacity) {
//   id = id + 1;
//   return { id, wtp, source, capacity};
// }

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {
  return (
    <React.Fragment>
      <Title>Net Surface Water Assets</Title>
      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}></TableCell>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold', backgroundColor: '#ebebeb'}}>31 Dec 2018</TableCell>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold', backgroundColor: '#ebebeb'}}>31 Dec 2017</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{backgroundColor: '#ebebeb'}}>
            <TableCell sx={{fontWeight: 'bold'}}>Net Surface Water Assets (A<sub>sws</sub> - L<sub>sws</sub>)</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>(57)</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>(63)</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow sx={{backgroundColor: '#ebebeb'}}>
            <TableCell sx={{fontStyle: 'italic'}}>Change in Net Surface Water Assets</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontStyle: 'italic'}}>6</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
