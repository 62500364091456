import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';

let id = -1
// Generate Order Data
function createData(num) {
  id = id + 1;
  let wri = num;
  let benefit = num;
  let impact = Math.round((1 - num) * 10) / 10;
  return { id, wri, benefit, impact};
}

const rows = [
  createData(1),
  createData(0.9),
  createData(0.8),
  createData(0.7),
  createData(0.6),
  createData(0.5),
  createData(0.4),
  createData(0.3),
  createData(0.2),
  createData(0.1),
  createData(0),
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {
  return (
    <React.Fragment>
      {/* <Title>Water Users Table</Title> */}
      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>WRI</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Benefit rating B</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Impact rating I</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center">{row.wri}</TableCell>
              <TableCell align="center">{row.benefit}</TableCell>
              <TableCell align="center">{row.impact}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
