import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';
import './WaterAllocation.css';

// https://shancarter.github.io/mr-data-converter/
const rows = [
  {"no":1,"pump_house":"Alor Sena","source":"Sungai Santan","jan":0.18,"feb":"-","mar":0.19,"apr":1.01,"may":0.45,"jun":0.47,"jul":0.39,"aug":0.04,"sep":0.62,"oct":0.32,"nov":0.28,"dec":0.55,"total":4.51},
  {"no":2,"pump_house":"Semadong (ETT)","source":"Timah Tasoh Dam","jan":0.04,"feb":"-","mar":0.04,"apr":0.20,"may":0.09,"jun":0.09,"jul":0.08,"aug":0.01,"sep":0.12,"oct":0.06,"nov":0.06,"dec":0.11,"total":0.90},
  {"no":3,"pump_house":"Semadong (Skim)","source":"Timah Tasoh Dam","jan":0.04,"feb":"-","mar":0.04,"apr":0.20,"may":0.09,"jun":0.09,"jul":0.08,"aug":0.01,"sep":0.12,"oct":0.06,"nov":0.06,"dec":0.11,"total":0.90},
  {"no":4,"pump_house":"Bukit Tau","source":"Kolam Takungan","jan":0.09,"feb":"-","mar":0.10,"apr":0.51,"may":0.23,"jun":0.24,"jul":0.19,"aug":0.02,"sep":0.31,"oct":0.16,"nov":0.14,"dec":0.27,"total":4.51},
  {"no":5,"pump_house":"Kampong Belukar","source":"Sungai Korok","jan":0.05,"feb":"-","mar":0.06,"apr":0.30,"may":0.14,"jun":0.14,"jul":0.12,"aug":0.01,"sep":0.19,"oct":0.10,"nov":0.09,"dec":0.16,"total":0.90},
  {"no":6,"pump_house":"Sungai Jarum","source":"Sungai Jarum","jan":0.04,"feb":"-","mar":0.04,"apr":0.20,"may":0.09,"jun":0.09,"jul":0.08,"aug":0.01,"sep":0.12,"oct":0.06,"nov":0.06,"dec":0.11,"total":0.00},
  {"no":7,"pump_house":"Abi Kurung Batang","source":"Groundwater","jan":0.04,"feb":"-","mar":0.04,"apr":0.20,"may":0.09,"jun":0.09,"jul":0.08,"aug":0.01,"sep":0.12,"oct":0.06,"nov":0.06,"dec":0.11,"total":2.25},
  {"no":8,"pump_house":"Sungai Jernih","source":"Sungai Jernih","jan":0.04,"feb":"-","mar":0.04,"apr":0.20,"may":0.09,"jun":0.09,"jul":0.08,"aug":0.01,"sep":0.12,"oct":0.06,"nov":0.06,"dec":0.11,"total":1.35},
  {"no":9,"pump_house":"Sg. Gial","source":"NA","jan":0.35,"feb":"-","mar":0.39,"apr":2.03,"may":0.91,"jun":0.95,"jul":0.77,"aug":0.08,"sep":1.24,"oct":0.64,"nov":0.57,"dec":1.09,"total":0.90},
  {"no":10,"pump_house":"Sg. Jejawi / T1","source":"NA","jan":0.18,"feb":"-","mar":0.19,"apr":1.01,"may":0.45,"jun":0.47,"jul":0.39,"aug":0.04,"sep":0.62,"oct":0.32,"nov":0.28,"dec":0.55,"total":0.90},
  {"no":11,"pump_house":"Sg. Jejawi / T3","source":"NA","jan":0.18,"feb":"-","mar":0.19,"apr":1.01,"may":0.45,"jun":0.47,"jul":0.39,"aug":0.04,"sep":0.62,"oct":0.32,"nov":0.28,"dec":0.55,"total":0.90},
  {"no":12,"pump_house":"Sg. Jejawi / T8","source":"NA","jan":0.09,"feb":"-","mar":0.10,"apr":0.51,"may":0.23,"jun":0.24,"jul":0.19,"aug":0.02,"sep":0.31,"oct":0.16,"nov":0.14,"dec":0.27,"total":9.02},
  {"no":13,"pump_house":"Sg. Seriab A28","source":"NA","jan":0.09,"feb":"-","mar":0.10,"apr":0.51,"may":0.23,"jun":0.24,"jul":0.19,"aug":0.02,"sep":0.31,"oct":0.16,"nov":0.14,"dec":0.27,"total":4.51},
  {"no":14,"pump_house":"Sg. Seriab A25","source":"NA","jan":0.09,"feb":"-","mar":0.10,"apr":0.51,"may":0.23,"jun":0.24,"jul":0.19,"aug":0.02,"sep":0.31,"oct":0.16,"nov":0.14,"dec":0.27,"total":4.51},
  {"no":15,"pump_house":"Tok Kuning / Wang Bintong","source":"NA","jan":0.25,"feb":"-","mar":0.27,"apr":1.42,"may":0.63,"jun":0.66,"jul":0.54,"aug":0.06,"sep":0.87,"oct":0.45,"nov":0.40,"dec":0.76,"total":2.25},
  {"no":16,"pump_house":"Sg. Kayang","source":"NA","jan":0.62,"feb":"-","mar":0.68,"apr":3.55,"may":1.59,"jun":1.66,"jul":1.35,"aug":0.14,"sep":2.18,"oct":1.12,"nov":0.99,"dec":1.91,"total":2.25}
]


function preventDefault(event) {
  event.preventDefault();
}

export default function WaterAllocationIrrigation() {
  return (
    <React.Fragment>
      <Title>c. Irrigation</Title>
      <Table className="water-allocation-table" size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}} rowSpan={2}>No</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}} rowSpan={2}>Pump House</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}} rowSpan={2}>Water Source</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}} colSpan={12}>Monthly Total Abstraction (MCM) for Year 2021</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}} rowSpan={2}>Annual Total (MCM)</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Jan</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Feb</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Mar</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Apr</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>May</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Jun</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Jul</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Aug</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Sep</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Oct</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Nov</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Dec</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="center">{row.no}</TableCell>
              <TableCell align="center">{row.pump_house}</TableCell>
              <TableCell align="center">{row.source}</TableCell>
              <TableCell align="center">{row.jan}</TableCell>
              <TableCell align="center">{row.feb}</TableCell>
              <TableCell align="center">{row.mar}</TableCell>
              <TableCell align="center">{row.apr}</TableCell>
              <TableCell align="center">{row.may}</TableCell>
              <TableCell align="center">{row.jun}</TableCell>
              <TableCell align="center">{row.jul}</TableCell>
              <TableCell align="center">{row.aug}</TableCell>
              <TableCell align="center">{row.sep}</TableCell>
              <TableCell align="center">{row.oct}</TableCell>
              <TableCell align="center">{row.nov}</TableCell>
              <TableCell align="center">{row.dec}</TableCell>
              <TableCell align="center">{row.total}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={3} align="center" sx={{fontWeight: 'bold'}}>Total</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>2.30</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>0.00</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>2.52</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>13.17</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>5.89</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>6.15</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>5.02</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>0.52</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>8.09</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>4.16</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>3.69</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>7.09</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>58.60</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
