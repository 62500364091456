
// Data can generate using https://shancarter.github.io/mr-data-converter/
// Paste time and value column from Excel, then choose Output as "JSON - Row Arrays"
export const waterDemandData = [
    {
        name: "WTP Abstraction",
        yAxis: {
            nameLocation: 'middle',
            name: "Discharge m{sup|3}/s",
            nameGap: 50,
            left: 30,
            nameTextStyle: {
                fontSize: 20,
                rich: {
                  sup: {
                    verticalAlign: 'top',
                    lineHeight: 10,
                    fontSize: 12,
                  },
                }
            },
        },
        itemStyle: {
            color: 'red',
        },
        timeSeries: [
            ["1/1/2023",0.47],
            ["2/1/2023",0.49],
            ["3/1/2023",0.48],
            ["4/1/2023",0.49],
            ["5/1/2023",0.46],
            ["6/1/2023",0.48],
            ["7/1/2023",0.46],
            ["8/1/2023",0.47],
            ["9/1/2023",0.46],
            ["10/1/2023",0.45],
            ["11/1/2023",0.47],
            ["12/1/2023",0.46],
            ["13/1/2023",0.45],
            ["14/1/2023",0.45],
            ["15/1/2023",0.47],
            ["16/1/2023",0.47],
            ["17/1/2023",0.46],
            ["18/1/2023",0.47],
            ["19/1/2023",0.5],
            ["20/1/2023",0.47],
            ["21/1/2023",0.46],
            ["22/1/2023",0.46],
            ["23/1/2023",0.47],
            ["24/1/2023",0.48],
            ["25/1/2023",0.48],
            ["26/1/2023",0.47],
            ["27/1/2023",0.46],
            ["28/1/2023",0.48],
            ["29/1/2023",0.46],
            ["30/1/2023",0.45],
            ["31/1/2023",0.46],
            ["1/2/2023",0.47],
            ["2/2/2023",0.45],
            ["3/2/2023",0.46],
            ["4/2/2023",0.45],
            ["5/2/2023",0.48],
            ["6/2/2023",0.47],
            ["7/2/2023",0.47],
            ["8/2/2023",0.47],
            ["9/2/2023",0.44],
            ["10/2/2023",0.47],
            ["11/2/2023",0.5],
            ["12/2/2023",0.49],
            ["13/2/2023",0.49],
            ["14/2/2023",0.5],
            ["15/2/2023",0.49],
            ["16/2/2023",0.49],
            ["17/2/2023",0.49],
            ["18/2/2023",0.49],
            ["19/2/2023",0.49],
            ["20/2/2023",0.48],
            ["21/2/2023",0.49],
            ["22/2/2023",0.49],
            ["23/2/2023",0.49],
            ["24/2/2023",0.46],
            ["25/2/2023",0.49],
            ["26/2/2023",0.51],
            ["27/2/2023",0.48],
            ["28/2/2023",0.48],
            ["1/3/2023",0.5],
            ["2/3/2023",0.49],
            ["3/3/2023",0.49],
            ["4/3/2023",0.44],
            ["5/3/2023",0.5],
            ["6/3/2023",0.48],
            ["7/3/2023",0.48],
            ["8/3/2023",0.47],
            ["9/3/2023",0.49],
            ["10/3/2023",0.49],
            ["11/3/2023",0.49],
            ["12/3/2023",0.44],
            ["13/3/2023",0.45],
            ["14/3/2023",0.45],
            ["15/3/2023",0.46],
            ["16/3/2023",0.44],
            ["17/3/2023",0.47],
            ["18/3/2023",0.45],
            ["19/3/2023",0.46],
            ["20/3/2023",0.45],
            ["21/3/2023",0.46],
            ["22/3/2023",0.46],
            ["23/3/2023",0.45],
            ["24/3/2023",0.45],
            ["25/3/2023",0.46],
            ["26/3/2023",0.5],
            ["27/3/2023",0.51],
            ["28/3/2023",0.5],
            ["29/3/2023",0.52],
            ["30/3/2023",0.51],
            ["31/3/2023",0.52],
            ["1/4/2023",0.5],
            ["2/4/2023",0.51],
            ["3/4/2023",0.51],
            ["4/4/2023",0.5],
            ["5/4/2023",0.51],
            ["6/4/2023",0.52],
            ["7/4/2023",0.51],
            ["8/4/2023",0.51],
            ["9/4/2023",0.51],
            ["10/4/2023",0.51],
            ["11/4/2023",0.41],
            ["12/4/2023",0.49],
            ["13/4/2023",0.51],
            ["14/4/2023",0.51],
            ["15/4/2023",0.5],
            ["16/4/2023",0.51],
            ["17/4/2023",0.5],
            ["18/4/2023",0.51],
            ["19/4/2023",0.5],
            ["20/4/2023",0.5],
            ["21/4/2023",0.5],
            ["22/4/2023",0.51],
            ["23/4/2023",0.51],
            ["24/4/2023",0.5],
            ["25/4/2023",0.5],
            ["26/4/2023",0.5],
            ["27/4/2023",0.5],
            ["28/4/2023",0.51],
            ["29/4/2023",0.51],
            ["30/4/2023",0.51],
            ["1/5/2023",0.5],
            ["2/5/2023",0.51],
            ["3/5/2023",0.5],
            ["4/5/2023",0.51],
            ["5/5/2023",0.51],
            ["6/5/2023",0.51],
            ["7/5/2023",0.51],
            ["8/5/2023",0.52],
            ["9/5/2023",0.51],
            ["10/5/2023",0.51],
            ["11/5/2023",0.51],
            ["12/5/2023",0.51],
            ["13/5/2023",0.51],
            ["14/5/2023",0.5],
            ["15/5/2023",0.5],
            ["16/5/2023",0.51],
            ["17/5/2023",0.5],
            ["18/5/2023",0.51],
            ["19/5/2023",0.5],
            ["20/5/2023",0.5],
            ["21/5/2023",0.5],
            ["22/5/2023",0.5],
            ["23/5/2023",0.5],
            ["24/5/2023",0.5],
            ["25/5/2023",0.49],
            ["26/5/2023",0.49],
            ["27/5/2023",0.5],
            ["28/5/2023",0.35],
            ["29/5/2023",0.35],
            ["30/5/2023",0.35],
            ["31/5/2023",0.35],
            ["1/6/2023",0.5],
            ["2/6/2023",0.53],
            ["3/6/2023",0.58],
            ["4/6/2023",0.58],
            ["5/6/2023",0.58],
            ["6/6/2023",0.58],
            ["7/6/2023",0.57],
            ["8/6/2023",0.58],
            ["9/6/2023",0.59],
            ["10/6/2023",0.58],
            ["11/6/2023",0.58],
            ["12/6/2023",0.58],
            ["13/6/2023",0.58],
            ["14/6/2023",0.58],
            ["15/6/2023",0.57],
            ["16/6/2023",0.58],
            ["17/6/2023",0.57],
            ["18/6/2023",0.57],
            ["19/6/2023",0.57],
            ["20/6/2023",0.58],
            ["21/6/2023",0.57],
            ["22/6/2023",0.58],
            ["23/6/2023",0.61],
            ["24/6/2023",0.59],
            ["25/6/2023",0.6],
            ["26/6/2023",0.56],
            ["27/6/2023",0.58],
            ["28/6/2023",0.57],
            ["29/6/2023",0.59],
            ["30/6/2023",0.61],
            ["1/7/2023",0.6],
            ["2/7/2023",0.61],
            ["3/7/2023",0.61],
            ["4/7/2023",0.61],
            ["5/7/2023",0.61],
            ["6/7/2023",0.6],
            ["7/7/2023",0.61],
            ["8/7/2023",0.62],
            ["9/7/2023",0.6],
            ["10/7/2023",0.59],
            ["11/7/2023",0.58],
            ["12/7/2023",0.6],
            ["13/7/2023",0.58],
            ["14/7/2023",0.59],
            ["15/7/2023",0.59],
            ["16/7/2023",0.59],
            ["17/7/2023",0.58],
            ["18/7/2023",0.58],
            ["19/7/2023",0.59],
            ["20/7/2023",0.6],
            ["21/7/2023",0.59],
            ["22/7/2023",0.6],
            ["23/7/2023",0.61],
            ["24/7/2023",0.6],
            ["25/7/2023",0.6],
            ["26/7/2023",0.59],
            ["27/7/2023",0.59],
            ["28/7/2023",0.59],
            ["29/7/2023",0.59],
            ["30/7/2023",0.59],
            ["31/7/2023",0.6],
            ["1/8/2023",0.59],
            ["2/8/2023",0.59],
            ["3/8/2023",0.6],
            ["4/8/2023",0.61],
            ["5/8/2023",0.61],
            ["6/8/2023",0.6],
            ["7/8/2023",0.6],
            ["8/8/2023",0.6],
            ["9/8/2023",0.6],
            ["10/8/2023",0.6],
            ["11/8/2023",0.61],
            ["12/8/2023",0.61],
            ["13/8/2023",0.61],
            ["14/8/2023",0.6],
            ["15/8/2023",0.6],
            ["16/8/2023",0.61],
            ["17/8/2023",0.6],
            ["18/8/2023",0.6],
            ["19/8/2023",0.52],
            ["20/8/2023",0.6],
            ["21/8/2023",0.61],
            ["22/8/2023",0.59],
            ["23/8/2023",0.59],
            ["24/8/2023",0.61],
            ["25/8/2023",0.6],
            ["26/8/2023",0.6],
            ["27/8/2023",0.6],
            ["28/8/2023",0.6],
            ["29/8/2023",0.61],
            ["30/8/2023",0.61],
            ["31/8/2023",0.62],
            ["1/9/2023",0.61],
            ["2/9/2023",0.63],
            ["3/9/2023",0.6],
            ["4/9/2023",0.62],
            ["5/9/2023",0.61],
            ["6/9/2023",0.59],
            ["7/9/2023",0.58],
            ["8/9/2023",0.6],
            ["9/9/2023",0.58],
            ["10/9/2023",0.6],
            ["11/9/2023",0.59],
            ["12/9/2023",0.58],
            ["13/9/2023",0.6],
            ["14/9/2023",0.6],
            ["15/9/2023",0.59],
            ["16/9/2023",0.6],
            ["17/9/2023",0.6],
            ["18/9/2023",0.6],
            ["19/9/2023",0.6],
            ["20/9/2023",0.6],
            ["21/9/2023",0.6],
            ["22/9/2023",0.6],
            ["23/9/2023",0.58],
            ["24/9/2023",0.61],
            ["25/9/2023",0.6],
            ["26/9/2023",0.6],
            ["27/9/2023",0.59],
            ["28/9/2023",0.6],
            ["29/9/2023",0.58],
            ["30/9/2023",0.6],
            ["1/10/2023",0.58],
            ["2/10/2023",0.62],
            ["3/10/2023",0.58],
            ["4/10/2023",0.6],
            ["5/10/2023",0.6],
            ["6/10/2023",0.62],
            ["7/10/2023",0.59],
            ["8/10/2023",0.6],
            ["9/10/2023",0.59],
            ["10/10/2023",0.6],
            ["11/10/2023",0.59],
            ["12/10/2023",0.61],
            ["13/10/2023",0.59],
            ["14/10/2023",0.58],
            ["15/10/2023",0.59],
            ["16/10/2023",0.6],
            ["17/10/2023",0.6],
            ["18/10/2023",0.6],
            ["19/10/2023",0.6],
            ["20/10/2023",0.61],
            ["21/10/2023",0.61],
            ["22/10/2023",0.62],
            ["23/10/2023",0.6],
            ["24/10/2023",0.61],
            ["25/10/2023",0.59],
            ["26/10/2023",0.58],
            ["27/10/2023",0.58],
            ["28/10/2023",0.59],
            ["29/10/2023",0.58],
            ["30/10/2023",0.58],
            ["31/10/2023",0.59],
            ["1/11/2023",0.59],
            ["2/11/2023",0.59],
            ["3/11/2023",0.58],
            ["4/11/2023",0.59],
            ["5/11/2023",0.6],
            ["6/11/2023",0.59],
            ["7/11/2023",0.59],
            ["8/11/2023",0.6],
            ["9/11/2023",0.6],
            ["10/11/2023",0.57],
            ["11/11/2023",0.6],
            ["12/11/2023",0.6],
            ["13/11/2023",0.61],
            ["14/11/2023",0.6],
            ["15/11/2023",0.59],
            ["16/11/2023",0.58],
            ["17/11/2023",0.6],
            ["18/11/2023",0.59],
            ["19/11/2023",0.59],
            ["20/11/2023",0.59],
            ["21/11/2023",0.59],
            ["22/11/2023",0.58],
            ["23/11/2023",0.58],
            ["24/11/2023",0.57],
            ["25/11/2023",0.59],
            ["26/11/2023",0.6],
            ["27/11/2023",0.59],
            ["28/11/2023",0.59],
            ["29/11/2023",0.6],
            ["30/11/2023",0.61],
            ["1/12/2023",0.37],
            ["2/12/2023",0.51],
            ["3/12/2023",0.56],
            ["4/12/2023",0.57],
            ["5/12/2023",0.56],
            ["6/12/2023",0.58],
            ["7/12/2023",0.59],
            ["8/12/2023",0.58],
            ["9/12/2023",0.57],
            ["10/12/2023",0.57],
            ["11/12/2023",0.59],
            ["12/12/2023",0.58],
            ["13/12/2023",0.59],
            ["14/12/2023",0.58],
            ["15/12/2023",0.6],
            ["16/12/2023",0.58],
            ["17/12/2023",0.6],
            ["18/12/2023",0.58],
            ["19/12/2023",0.59],
            ["20/12/2023",0.56],
            ["21/12/2023",0.6],
            ["22/12/2023",0.6],
            ["23/12/2023",0.61],
            ["24/12/2023",0.6],
            ["25/12/2023",0.61],
            ["26/12/2023",0.61],
            ["27/12/2023",0.61],
            ["28/12/2023",0.6],
            ["29/12/2023",0.59],
            ["30/12/2023",0.61],
            ["31/12/2023",0.62]
        ]
    },
    {
        name: "Design Capacity",
        // lineStyle: 'dashed',
        xAxisShow: false,
        // lineColor: 'red',
        itemStyle: {
            color: 'blue',
        },
        timeSeries: [
            ["1/1/2023",0.6],
            ["2/1/2023",0.6],
            ["3/1/2023",0.6],
            ["4/1/2023",0.6],
            ["5/1/2023",0.6],
            ["6/1/2023",0.6],
            ["7/1/2023",0.6],
            ["8/1/2023",0.6],
            ["9/1/2023",0.6],
            ["10/1/2023",0.6],
            ["11/1/2023",0.6],
            ["12/1/2023",0.6],
            ["13/1/2023",0.6],
            ["14/1/2023",0.6],
            ["15/1/2023",0.6],
            ["16/1/2023",0.6],
            ["17/1/2023",0.6],
            ["18/1/2023",0.6],
            ["19/1/2023",0.6],
            ["20/1/2023",0.6],
            ["21/1/2023",0.6],
            ["22/1/2023",0.6],
            ["23/1/2023",0.6],
            ["24/1/2023",0.6],
            ["25/1/2023",0.6],
            ["26/1/2023",0.6],
            ["27/1/2023",0.6],
            ["28/1/2023",0.6],
            ["29/1/2023",0.6],
            ["30/1/2023",0.6],
            ["31/1/2023",0.6],
            ["1/2/2023",0.6],
            ["2/2/2023",0.6],
            ["3/2/2023",0.6],
            ["4/2/2023",0.6],
            ["5/2/2023",0.6],
            ["6/2/2023",0.6],
            ["7/2/2023",0.6],
            ["8/2/2023",0.6],
            ["9/2/2023",0.6],
            ["10/2/2023",0.6],
            ["11/2/2023",0.6],
            ["12/2/2023",0.6],
            ["13/2/2023",0.6],
            ["14/2/2023",0.6],
            ["15/2/2023",0.6],
            ["16/2/2023",0.6],
            ["17/2/2023",0.6],
            ["18/2/2023",0.6],
            ["19/2/2023",0.6],
            ["20/2/2023",0.6],
            ["21/2/2023",0.6],
            ["22/2/2023",0.6],
            ["23/2/2023",0.6],
            ["24/2/2023",0.6],
            ["25/2/2023",0.6],
            ["26/2/2023",0.6],
            ["27/2/2023",0.6],
            ["28/2/2023",0.6],
            ["1/3/2023",0.6],
            ["2/3/2023",0.6],
            ["3/3/2023",0.6],
            ["4/3/2023",0.6],
            ["5/3/2023",0.6],
            ["6/3/2023",0.6],
            ["7/3/2023",0.6],
            ["8/3/2023",0.6],
            ["9/3/2023",0.6],
            ["10/3/2023",0.6],
            ["11/3/2023",0.6],
            ["12/3/2023",0.6],
            ["13/3/2023",0.6],
            ["14/3/2023",0.6],
            ["15/3/2023",0.6],
            ["16/3/2023",0.6],
            ["17/3/2023",0.6],
            ["18/3/2023",0.6],
            ["19/3/2023",0.6],
            ["20/3/2023",0.6],
            ["21/3/2023",0.6],
            ["22/3/2023",0.6],
            ["23/3/2023",0.6],
            ["24/3/2023",0.6],
            ["25/3/2023",0.6],
            ["26/3/2023",0.6],
            ["27/3/2023",0.6],
            ["28/3/2023",0.6],
            ["29/3/2023",0.6],
            ["30/3/2023",0.6],
            ["31/3/2023",0.6],
            ["1/4/2023",0.6],
            ["2/4/2023",0.6],
            ["3/4/2023",0.6],
            ["4/4/2023",0.6],
            ["5/4/2023",0.6],
            ["6/4/2023",0.6],
            ["7/4/2023",0.6],
            ["8/4/2023",0.6],
            ["9/4/2023",0.6],
            ["10/4/2023",0.6],
            ["11/4/2023",0.6],
            ["12/4/2023",0.6],
            ["13/4/2023",0.6],
            ["14/4/2023",0.6],
            ["15/4/2023",0.6],
            ["16/4/2023",0.6],
            ["17/4/2023",0.6],
            ["18/4/2023",0.6],
            ["19/4/2023",0.6],
            ["20/4/2023",0.6],
            ["21/4/2023",0.6],
            ["22/4/2023",0.6],
            ["23/4/2023",0.6],
            ["24/4/2023",0.6],
            ["25/4/2023",0.6],
            ["26/4/2023",0.6],
            ["27/4/2023",0.6],
            ["28/4/2023",0.6],
            ["29/4/2023",0.6],
            ["30/4/2023",0.6],
            ["1/5/2023",0.6],
            ["2/5/2023",0.6],
            ["3/5/2023",0.6],
            ["4/5/2023",0.6],
            ["5/5/2023",0.6],
            ["6/5/2023",0.6],
            ["7/5/2023",0.6],
            ["8/5/2023",0.6],
            ["9/5/2023",0.6],
            ["10/5/2023",0.6],
            ["11/5/2023",0.6],
            ["12/5/2023",0.6],
            ["13/5/2023",0.6],
            ["14/5/2023",0.6],
            ["15/5/2023",0.6],
            ["16/5/2023",0.6],
            ["17/5/2023",0.6],
            ["18/5/2023",0.6],
            ["19/5/2023",0.6],
            ["20/5/2023",0.6],
            ["21/5/2023",0.6],
            ["22/5/2023",0.6],
            ["23/5/2023",0.6],
            ["24/5/2023",0.6],
            ["25/5/2023",0.6],
            ["26/5/2023",0.6],
            ["27/5/2023",0.6],
            ["28/5/2023",0.6],
            ["29/5/2023",0.6],
            ["30/5/2023",0.6],
            ["31/5/2023",0.6],
            ["1/6/2023",0.6],
            ["2/6/2023",0.6],
            ["3/6/2023",0.6],
            ["4/6/2023",0.6],
            ["5/6/2023",0.6],
            ["6/6/2023",0.6],
            ["7/6/2023",0.6],
            ["8/6/2023",0.6],
            ["9/6/2023",0.6],
            ["10/6/2023",0.6],
            ["11/6/2023",0.6],
            ["12/6/2023",0.6],
            ["13/6/2023",0.6],
            ["14/6/2023",0.6],
            ["15/6/2023",0.6],
            ["16/6/2023",0.6],
            ["17/6/2023",0.6],
            ["18/6/2023",0.6],
            ["19/6/2023",0.6],
            ["20/6/2023",0.6],
            ["21/6/2023",0.6],
            ["22/6/2023",0.6],
            ["23/6/2023",0.6],
            ["24/6/2023",0.6],
            ["25/6/2023",0.6],
            ["26/6/2023",0.6],
            ["27/6/2023",0.6],
            ["28/6/2023",0.6],
            ["29/6/2023",0.6],
            ["30/6/2023",0.6],
            ["1/7/2023",0.6],
            ["2/7/2023",0.6],
            ["3/7/2023",0.6],
            ["4/7/2023",0.6],
            ["5/7/2023",0.6],
            ["6/7/2023",0.6],
            ["7/7/2023",0.6],
            ["8/7/2023",0.6],
            ["9/7/2023",0.6],
            ["10/7/2023",0.6],
            ["11/7/2023",0.6],
            ["12/7/2023",0.6],
            ["13/7/2023",0.6],
            ["14/7/2023",0.6],
            ["15/7/2023",0.6],
            ["16/7/2023",0.6],
            ["17/7/2023",0.6],
            ["18/7/2023",0.6],
            ["19/7/2023",0.6],
            ["20/7/2023",0.6],
            ["21/7/2023",0.6],
            ["22/7/2023",0.6],
            ["23/7/2023",0.6],
            ["24/7/2023",0.6],
            ["25/7/2023",0.6],
            ["26/7/2023",0.6],
            ["27/7/2023",0.6],
            ["28/7/2023",0.6],
            ["29/7/2023",0.6],
            ["30/7/2023",0.6],
            ["31/7/2023",0.6],
            ["1/8/2023",0.6],
            ["2/8/2023",0.6],
            ["3/8/2023",0.6],
            ["4/8/2023",0.6],
            ["5/8/2023",0.6],
            ["6/8/2023",0.6],
            ["7/8/2023",0.6],
            ["8/8/2023",0.6],
            ["9/8/2023",0.6],
            ["10/8/2023",0.6],
            ["11/8/2023",0.6],
            ["12/8/2023",0.6],
            ["13/8/2023",0.6],
            ["14/8/2023",0.6],
            ["15/8/2023",0.6],
            ["16/8/2023",0.6],
            ["17/8/2023",0.6],
            ["18/8/2023",0.6],
            ["19/8/2023",0.6],
            ["20/8/2023",0.6],
            ["21/8/2023",0.6],
            ["22/8/2023",0.6],
            ["23/8/2023",0.6],
            ["24/8/2023",0.6],
            ["25/8/2023",0.6],
            ["26/8/2023",0.6],
            ["27/8/2023",0.6],
            ["28/8/2023",0.6],
            ["29/8/2023",0.6],
            ["30/8/2023",0.6],
            ["31/8/2023",0.6],
            ["1/9/2023",0.6],
            ["2/9/2023",0.6],
            ["3/9/2023",0.6],
            ["4/9/2023",0.6],
            ["5/9/2023",0.6],
            ["6/9/2023",0.6],
            ["7/9/2023",0.6],
            ["8/9/2023",0.6],
            ["9/9/2023",0.6],
            ["10/9/2023",0.6],
            ["11/9/2023",0.6],
            ["12/9/2023",0.6],
            ["13/9/2023",0.6],
            ["14/9/2023",0.6],
            ["15/9/2023",0.6],
            ["16/9/2023",0.6],
            ["17/9/2023",0.6],
            ["18/9/2023",0.6],
            ["19/9/2023",0.6],
            ["20/9/2023",0.6],
            ["21/9/2023",0.6],
            ["22/9/2023",0.6],
            ["23/9/2023",0.6],
            ["24/9/2023",0.6],
            ["25/9/2023",0.6],
            ["26/9/2023",0.6],
            ["27/9/2023",0.6],
            ["28/9/2023",0.6],
            ["29/9/2023",0.6],
            ["30/9/2023",0.6],
            ["1/10/2023",0.6],
            ["2/10/2023",0.6],
            ["3/10/2023",0.6],
            ["4/10/2023",0.6],
            ["5/10/2023",0.6],
            ["6/10/2023",0.6],
            ["7/10/2023",0.6],
            ["8/10/2023",0.6],
            ["9/10/2023",0.6],
            ["10/10/2023",0.6],
            ["11/10/2023",0.6],
            ["12/10/2023",0.6],
            ["13/10/2023",0.6],
            ["14/10/2023",0.6],
            ["15/10/2023",0.6],
            ["16/10/2023",0.6],
            ["17/10/2023",0.6],
            ["18/10/2023",0.6],
            ["19/10/2023",0.6],
            ["20/10/2023",0.6],
            ["21/10/2023",0.6],
            ["22/10/2023",0.6],
            ["23/10/2023",0.6],
            ["24/10/2023",0.6],
            ["25/10/2023",0.6],
            ["26/10/2023",0.6],
            ["27/10/2023",0.6],
            ["28/10/2023",0.6],
            ["29/10/2023",0.6],
            ["30/10/2023",0.6],
            ["31/10/2023",0.6],
            ["1/11/2023",0.6],
            ["2/11/2023",0.6],
            ["3/11/2023",0.6],
            ["4/11/2023",0.6],
            ["5/11/2023",0.6],
            ["6/11/2023",0.6],
            ["7/11/2023",0.6],
            ["8/11/2023",0.6],
            ["9/11/2023",0.6],
            ["10/11/2023",0.6],
            ["11/11/2023",0.6],
            ["12/11/2023",0.6],
            ["13/11/2023",0.6],
            ["14/11/2023",0.6],
            ["15/11/2023",0.6],
            ["16/11/2023",0.6],
            ["17/11/2023",0.6],
            ["18/11/2023",0.6],
            ["19/11/2023",0.6],
            ["20/11/2023",0.6],
            ["21/11/2023",0.6],
            ["22/11/2023",0.6],
            ["23/11/2023",0.6],
            ["24/11/2023",0.6],
            ["25/11/2023",0.6],
            ["26/11/2023",0.6],
            ["27/11/2023",0.6],
            ["28/11/2023",0.6],
            ["29/11/2023",0.6],
            ["30/11/2023",0.6],
            ["1/12/2023",0.6],
            ["2/12/2023",0.6],
            ["3/12/2023",0.6],
            ["4/12/2023",0.6],
            ["5/12/2023",0.6],
            ["6/12/2023",0.6],
            ["7/12/2023",0.6],
            ["8/12/2023",0.6],
            ["9/12/2023",0.6],
            ["10/12/2023",0.6],
            ["11/12/2023",0.6],
            ["12/12/2023",0.6],
            ["13/12/2023",0.6],
            ["14/12/2023",0.6],
            ["15/12/2023",0.6],
            ["16/12/2023",0.6],
            ["17/12/2023",0.6],
            ["18/12/2023",0.6],
            ["19/12/2023",0.6],
            ["20/12/2023",0.6],
            ["21/12/2023",0.6],
            ["22/12/2023",0.6],
            ["23/12/2023",0.6],
            ["24/12/2023",0.6],
            ["25/12/2023",0.6],
            ["26/12/2023",0.6],
            ["27/12/2023",0.6],
            ["28/12/2023",0.6],
            ["29/12/2023",0.6],
            ["30/12/2023",0.6],
            ["31/12/2023",0.6]
        ]
    }
]