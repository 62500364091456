import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';

let id = -1
// Generate Order Data
function createData(num, index, wtp) {
  id = id + 1;
  return { id, num, index, wtp};
}

const rows = [
  createData(1, '>0.7', 'Bukit Nanas'),
  createData(2, '0.6 - 0.7', 'Sg Rumput'),
  createData(3, '0.5 - 0.6', 'Kepong, Wangsa Maju, Sg Batu, Ampang, Sg Gombak'),
  createData(4, '<0.5', 'North Hummock'),
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {
  return (
    <React.Fragment>
      {/* <Title>Water Users Table</Title> */}
      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Water Priority</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Classification of Aggregated Index, I</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>WTP</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center">{row.num}</TableCell>
              <TableCell align="center">{row.index}</TableCell>
              <TableCell align="center">{row.wtp}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
