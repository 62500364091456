import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';

let id = -1
// Generate Order Data
function createData(month, abstraction) {
  id = id + 1;
  return { id, month, abstraction};
}

const rows = [
  createData('Jan', '5474.03'),
  createData('Feb', '5816.54'),
  createData('March', '5900.26'),
  createData('Apr', '5902.77'),
  createData('May', '5249.48'),
  createData('Jun', '5519.13'),
  createData('July', '5584.77'),
  createData('Aug', '5542.1'),
  createData('Sept', '5672.1'),
  createData('Oct', '5697.16'),
  createData('Nov', '5674.7'),
  createData('Dec', '5264.03'),
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {
  return (
    <React.Fragment>
      <Title>Water Demand Abstraction Table</Title>
      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Month</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Average Abstraction (m<sup>3</sup>/day)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center">{row.month}</TableCell>
              <TableCell align="center">{row.abstraction}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
