import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';
import './WaterAllocation.css';

// https://shancarter.github.io/mr-data-converter/
const rows = [
  {"no":1,"month":"Jan","inflow":29.8,"wtp":2.1,"irrigation":2.3,"others":27.4},
  {"no":2,"month":"Feb","inflow":15.7,"wtp":2.0,"irrigation":0.0,"others":22.7},
  {"no":3,"month":"Mar","inflow":21.8,"wtp":2.2,"irrigation":2.5,"others":34.6},
  {"no":4,"month":"Apr","inflow":20.8,"wtp":2.1,"irrigation":13.2,"others":22.1},
  {"no":5,"month":"May","inflow":19.2,"wtp":2.2,"irrigation":5.9,"others":17.5},
  {"no":6,"month":"Jun","inflow":16.3,"wtp":2.1,"irrigation":6.2,"others":17.8},
  {"no":7,"month":"Jul","inflow":19.6,"wtp":2.2,"irrigation":5.0,"others":23.6},
  {"no":8,"month":"Aug","inflow":18.0,"wtp":2.1,"irrigation":0.5,"others":25.1},
  {"no":9,"month":"Sep","inflow":31.6,"wtp":2.2,"irrigation":8.1,"others":32.0},
  {"no":10,"month":"Oct","inflow":22.2,"wtp":2.3,"irrigation":4.2,"others":39.9},
  {"no":11,"month":"Nov","inflow":26.3,"wtp":2.2,"irrigation":3.7,"others":40.1},
  {"no":12,"month":"Dec","inflow":25.5,"wtp":2.2,"irrigation":7.1,"others":34.3}
]


function preventDefault(event) {
  event.preventDefault();
}

export default function WaterAllocationirrigation() {
  return (
    <React.Fragment>
      <Title>Monthly Dam Total Inflow and Outflows</Title>
      <Table className="water-allocation-table" size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}} rowSpan={2}>No</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}} rowSpan={2}>Month</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}} rowSpan={2}>Inflow (MCM)</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}} colSpan={3}>Outflow (MCM)</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>WTP</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>irrigation</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Others</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="center">{row.no}</TableCell>
              <TableCell align="center">{row.month}</TableCell>
              <TableCell align="center">{row.inflow}</TableCell>
              <TableCell align="center">{row.wtp}</TableCell>
              <TableCell align="center">{row.irrigation}</TableCell>
              <TableCell align="center">{row.others}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold'}}>Total</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>266.8</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>25.8</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>58.6</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>337.2</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
