import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';

let id = -1
let num = 0
// Generate Order Data
function createData(wtp, source, capacity) {
  id = id + 1;
  num = id + 1;
  return { id, num, wtp, source, capacity};
}

const rows = [
  createData('Arau Phase I, II, III WTP', 'MADA Northern Canal & Groundwater', '52'),
  createData('Arau Phase IV Kg. Sena WTP', 'MADA Northern Canal', '123'),
  createData('Timah Tasoh WTP', 'Timah Tasoh Dam', '36'),
  createData('Alor Sena', 'Sungai Santan', '24.5'),
  createData('Semadong (ETT)', 'Timah Tasoh Dam', '24.5'),
  createData('Semadong (Skim)', 'Timah Tasoh Dam', '24.5'),
  createData('Bukit Tau', 'Kolam Takungan', '12.2'),
  createData('Kampong Belukar', 'Sungai Korok', '7.3'),
  createData('Sungai Jarum', 'Sungai Jarum', '4.9'),
  createData('Abi Kurung Batang', 'Groundwater', '4.9'),
  createData('Sungai Jernih', 'Sungai Jernih', '4.9'),
  createData('Sg. Gial', 'NA', '48.9'),
  createData('Sg. Jejawi / T1', 'NA', '24.5'),
  createData('Sg. Jejawi / T3', 'NA', '24.5'),
  createData('Sg. Jejawi / T8', 'NA', '12.2'),
  createData('Sg. Seriab A28', 'NA', '12.2'),
  createData('Sg. Seriab A25', 'NA', '12.2'),
  createData('Tok Kuning / Wang Bintong', 'NA', '34.3'),
  createData('Sg. Kayang', 'NA', '85.6'),
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {
  return (
    <React.Fragment>
      <Title>Water Users Table</Title>
      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>No</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Water Users</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Water Source</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Design Capacity/Pump Capacity (MLD)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center">{row.num}</TableCell>
              <TableCell align="center">{row.wtp}</TableCell>
              <TableCell align="center">{row.source}</TableCell>
              <TableCell align="center">{row.capacity}</TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell colSpan={3} align="center" sx={{fontWeight: 'bold'}}>Total</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>548.63</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
