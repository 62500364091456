import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';

let id = -1
// Generate Order Data
function createData(scale, definition, climate, resource) {
  id = id + 1;
  return { id, scale, definition, climate, resource};
}

const rows = [
  createData('>1', 'WRI ≥ 1', 'Flooding', 'Wet Season in river and reservoir over spilling flood water'),
  createData('1', '0.6 ≤ WRI < 1', 'Normal Weather', 'In-stream and reservoir storage enough for consumption and utilization'),
  createData('0.6', '0.4 ≤ WRI < 0.6', 'Moderate Drought', 'In-stream and reservoir storage just barely enough for consumption and utilization'),
  createData('0.4', '0.2 ≤ WRI < 0.4', 'Severe Drought', 'In-stream and reservoir storage has a risk of not enough for consumption and utilization'),
  createData('0.2', '0 ≤ WRI < 0.2', 'Extreme Drought', 'In-stream and reservoir storage not enough for consumption and utilization'),
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {
  return (
    <React.Fragment>
      {/* <Title>WRI Table</Title> */}
      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>WRI Scale</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Definition</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Climatic Conditions</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Water Resources Conditions</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Water Resources Operation Decision Guideline</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center">{row.scale}</TableCell>
              <TableCell align="center">{row.climate}</TableCell>
              <TableCell align="center">{row.resource}</TableCell>
            </TableRow>
          ))} */}
          <TableRow>
            <TableCell align="center">{rows[0].scale}</TableCell>
            <TableCell align="center">{rows[0].definition}</TableCell>
            <TableCell align="center">{rows[0].climate}</TableCell>
            <TableCell align="center">{rows[0].resource}</TableCell>
            <TableCell align="center" rowSpan={3} sx={{fontWeight: 'bold', backgroundColor: '#c6d9f1'}}>Normal Water Supply Scenario WRI &gt; 0.3</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">{rows[1].scale}</TableCell>
            <TableCell align="center">{rows[1].definition}</TableCell>
            <TableCell align="center">{rows[1].climate}</TableCell>
            <TableCell align="center">{rows[1].resource}</TableCell>
          </TableRow>
          <TableRow sx={{backgroundColor: 'yellow'}}>
            <TableCell align="center">{rows[2].scale}</TableCell>
            <TableCell align="center">{rows[2].definition}</TableCell>
            <TableCell align="center">{rows[2].climate}</TableCell>
            <TableCell align="center">{rows[2].resource}</TableCell>
          </TableRow>
          <TableRow sx={{backgroundColor: '#ffc000'}}>
            <TableCell align="center" rowSpan={2}>{rows[3].scale}</TableCell>
            <TableCell align="center" rowSpan={2}>{rows[3].definition}</TableCell>
            <TableCell align="center" rowSpan={2}>{rows[3].climate}</TableCell>
            <TableCell align="center" rowSpan={2}>{rows[3].resource}</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold', backgroundColor: '#fcd5b5'}}>Drought Operation Preparation Plan</TableCell>
          </TableRow>
          <TableRow>
          <TableCell align="center" rowSpan={3} sx={{fontWeight: 'bold', backgroundColor: '#e6b9b8'}}>
            Water Allocation and Rationing To Achieve "WRI ≥ 0.3" based on Multi-criteria Analysis</TableCell>
          </TableRow>
          <TableRow sx={{backgroundColor: '#ff7f7f'}}>
            <TableCell align="center" rowSpan={2}>{rows[4].scale}</TableCell>
            <TableCell align="center" rowSpan={2}>{rows[4].definition}</TableCell>
            <TableCell align="center" rowSpan={2}>{rows[4].climate}</TableCell>
            <TableCell align="center" rowSpan={2}>{rows[4].resource}</TableCell>
          </TableRow>

        </TableBody>
      </Table>
      {/* <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        Details
      </Link> */}
    </React.Fragment>
  );
}
