import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';

let id = -1
// Generate Order Data
function createData(date, tenomq, kemambongq, q) {
  id = id + 1;
  return { id, date, tenomq, kemambongq, q};
}

const rows = [
  createData('2023/1/1', '195.867', '92.302', '0.07'),
  createData('2023/1/2', '201.373', '79.412', '0.07'),
  createData('2023/1/3', '200.225', '68.108', '0.07'),
  createData('2023/1/4', '194.293', '57.784', '0.07'),
  createData('2023/1/5', '193.399', '57.612', '0.07'),
  createData('2023/1/6', '258.748', '72.186', '0.07'),
  createData('2023/1/7', '281.776', '91.745', '0.07'),
  createData('2023/1/8', '276.412', '86.086', '0.07'),
  createData('2023/1/9', '257.83', '76.311', '0.07'),
  createData('2023/1/10', '240.7', '70.613', '0.07'),
  createData('2023/1/11', '225.317', '66.745', '0.07'),
  createData('2023/1/12', '214.847', '65.381', '0.07'),
  createData('2023/1/13', '210.075', '65.995', '0.07'),
  createData('2023/1/14', '204.746', '66.511', '0.07'),
  createData('2023/1/15', '201.677', '106.555', '0.07'),
  createData('2023/1/16', '199.778', '102.778', '0.07'),
  createData('2023/1/17', '196.222', '94.960', '0.07'),
  createData('2023/1/18', '196.892', '87.973', '0.07'),
  createData('2023/1/19', '204.061', '82.721', '0.07'),
  createData('2023/1/20', '211.56', '82.114', '0.07'),
  createData('2023/1/21', '231.404', '125.257', '0.07'),
  createData('2023/1/22', '230.115', '111.878', '0.07'),
  createData('2023/1/23', '216.901', '92.610', '0.07'),
  createData('2023/1/24', '196.259', '73.137', '0.07'),
  createData('2023/1/25', '175.479', '57.525', '0.07'),
  createData('2023/1/26', '155.553', '47.112', '0.07'),
  createData('2023/1/27', '138.884', '42.224', '0.07'),
  createData('2023/1/28', '125.872', '38.291', '0.07'),
  createData('2023/1/29', '119.947', '46.238', '0.07'),
  createData('2023/1/30', '121.847', '62.008', '0.07'),
  createData('2023/1/31', '125.526', '64.221', '0.07'),
  createData('2023/2/1', '129.739', '62.417', '0.07'),
  createData('2023/2/2', '137.061', '58.541', '0.07'),
  createData('2023/2/3', '139.513', '51.770', '0.07'),
  createData('2023/2/4', '135.086', '44.129', '0.07'),
  createData('2023/2/5', '126.454', '38.545', '0.07'),
  createData('2023/2/6', '117.859', '34.996', '0.07'),
  createData('2023/2/7', '110.169', '32.871', '0.07'),
  createData('2023/2/8', '103.913', '31.615', '0.07'),
  createData('2023/2/9', '109.368', '30.854', '0.07'),
  createData('2023/2/10', '137.914', '96.909', '0.07'),
  createData('2023/2/11', '154.353', '93.113', '0.07'),
  createData('2023/2/12', '172.502', '84.969', '0.07'),
  createData('2023/2/13', '190.103', '91.291', '0.07'),
  createData('2023/2/14', '192.597', '80.241', '0.07'),
  createData('2023/2/15', '186.009', '67.837', '0.07'),
  createData('2023/2/16', '172.675', '55.497', '0.07'),
  createData('2023/2/17', '158.916', '45.981', '0.07'),
  createData('2023/2/18', '142.904', '39.193', '0.07'),
  createData('2023/2/19', '127.763', '34.899', '0.07'),
  createData('2023/2/20', '116.927', '32.329', '0.07'),
  createData('2023/2/21', '107.517', '30.813', '0.07'),
  createData('2023/2/22', '99.999', '31.340', '0.07'),
  createData('2023/2/23', '103.687', '57.867', '0.07'),
  createData('2023/2/24', '121.181', '68.635', '0.07'),
  createData('2023/2/25', '154.519', '116.290', '0.07'),
  createData('2023/2/26', '202.141', '134.073', '0.07'),
  createData('2023/2/27', '214.508', '113.502', '0.07'),
  createData('2023/2/28', '209.204', '89.926', '0.07'),
  createData('2023/3/1', '254.996', '75.178', '0.07'),
  createData('2023/3/2', '273.594', '65.512', '0.07'),
  createData('2023/3/3', '263.849', '56.156', '0.07'),
  createData('2023/3/4', '243.199', '46.691', '0.07'),
  createData('2023/3/5', '215.528', '39.451', '0.07'),
  createData('2023/3/6', '185.019', '34.755', '0.07'),
  createData('2023/3/7', '174.349', '34.367', '0.07'),
  createData('2023/3/8', '256.791', '35.689', '0.07'),
  createData('2023/3/9', '270.424', '37.840', '0.07'),
  createData('2023/3/10', '258.107', '36.709', '0.07'),
  createData('2023/3/11', '231.414', '33.769', '0.07'),
  createData('2023/3/12', '208.461', '32.608', '0.07'),
  createData('2023/3/13', '191.028', '30.793', '0.07'),
  createData('2023/3/14', '180.657', '34.090', '0.07'),
  createData('2023/3/15', '198.471', '61.635', '0.07'),
  createData('2023/3/16', '198.355', '60.539', '0.07'),
  createData('2023/3/17', '187.276', '53.141', '0.07'),
  createData('2023/3/18', '169.549', '44.061', '0.07'),
  createData('2023/3/19', '155.35', '37.802', '0.07'),
  createData('2023/3/20', '149.234', '33.244', '0.07'),
  createData('2023/3/21', '153.861', '30.310', '0.07'),
  createData('2023/3/22', '159.551', '29.485', '0.07'),
  createData('2023/3/23', '159.838', '28.307', '0.07'),
  createData('2023/3/24', '163.818', '34.284', '0.07'),
  createData('2023/3/25', '166.659', '40.399', '0.07'),
  createData('2023/3/26', '173.777', '89.742', '0.07'),
  createData('2023/3/27', '220.086', '137.050', '0.07'),
  createData('2023/3/28', '236.881', '134.147', '0.07'),
  createData('2023/3/29', '240.215', '144.826', '0.07'),
  createData('2023/3/30', '240.803', '137.165', '0.07'),
  createData('2023/3/31', '233.709', '111.016', '0.07'),
  createData('2023/4/1', '223.233', '88.679', '0.07'),
  createData('2023/4/2', '235.939', '79.915', '0.07'),
  createData('2023/4/3', '243.695', '87.512', '0.07'),
  createData('2023/4/4', '328.641', '101.854', '0.07'),
  createData('2023/4/5', '404.105', '216.856', '0.07'),
  createData('2023/4/6', '404.85', '189.576', '0.07'),
  createData('2023/4/7', '415.954', '187.498', '0.07'),
  createData('2023/4/8', '481.616', '228.386', '0.07'),
  createData('2023/4/9', '518.902', '328.826', '0.07'),
  createData('2023/4/10', '502.346', '263.317', '0.07'),
  createData('2023/4/11', '580.352', '317.546', '0.07'),
  createData('2023/4/12', '588.185', '249.163', '0.07'),
  createData('2023/4/13', '621.376', '393.402', '0.07'),
  createData('2023/4/14', '580.172', '381.781', '0.07'),
  createData('2023/4/15', '541.785', '345.722', '0.07'),
  createData('2023/4/16', '477.539', '258.426', '0.07'),
  createData('2023/4/17', '412.581', '189.457', '0.07'),
  createData('2023/4/18', '397.555', '181.873', '0.07'),
  createData('2023/4/19', '398.671', '174.845', '0.07'),
  createData('2023/4/20', '461.335', '226.604', '0.07'),
  createData('2023/4/21', '446.829', '186.329', '0.07'),
  createData('2023/4/22', '430.425', '238.479', '0.07'),
  createData('2023/4/23', '401.259', '194.101', '0.07'),
  createData('2023/4/24', '419.101', '213.972', '0.07'),
  createData('2023/4/25', '396.573', '173.039', '0.07'),
  createData('2023/4/26', '370.831', '141.417', '0.07'),
  createData('2023/4/27', '369.083', '168.168', '0.07'),
  createData('2023/4/28', '390.369', '174.753', '0.07'),
  createData('2023/4/29', '390.1', '145.647', '0.07'),
  createData('2023/4/30', '369.775', '158.970', '0.07'),
  createData('2023/5/1', '341.33', '132.515', '0.07'),
  createData('2023/5/2', '313.399', '112.358', '0.07'),
  createData('2023/5/3', '292.355', '99.024', '0.07'),
  createData('2023/5/4', '274.668', '89.061', '0.07'),
  createData('2023/5/5', '255.208', '77.649', '0.07'),
  createData('2023/5/6', '233.916', '66.569', '0.07'),
  createData('2023/5/7', '212.925', '59.449', '0.07'),
  createData('2023/5/8', '197.755', '52.265', '0.07'),
  createData('2023/5/9', '196.944', '83.682', '0.07'),
  createData('2023/5/10', '247.893', '215.942', '0.07'),
  createData('2023/5/11', '370.084', '357.420', '0.07'),
  createData('2023/5/12', '464.714', '502.528', '0.07'),
  createData('2023/5/13', '468.382', '448.866', '0.07'),
  createData('2023/5/14', '447.263', '331.169', '0.07'),
  createData('2023/5/15', '409.121', '245.986', '0.07'),
  createData('2023/5/16', '398.931', '201.600', '0.07'),
  createData('2023/5/17', '376.543', '159.450', '0.07'),
  createData('2023/5/18', '384.291', '147.952', '0.07'),
  createData('2023/5/19', '413.961', '232.148', '0.07'),
  createData('2023/5/20', '412.269', '195.936', '0.07'),
  createData('2023/5/21', '433.453', '285.523', '0.07'),
  createData('2023/5/22', '441.202', '267.966', '0.07'),
  createData('2023/5/23', '420.348', '211.963', '0.07'),
  createData('2023/5/24', '426.11', '218.959', '0.07'),
  createData('2023/5/25', '400.245', '176.291', '0.07'),
  createData('2023/5/26', '363.896', '138.697', '0.07'),
  createData('2023/5/27', '326.872', '114.289', '0.07'),
  createData('2023/5/28', '297.904', '104.818', '0.07'),
  createData('2023/5/29', '295.961', '165.298', '0.07'),
  createData('2023/5/30', '350.055', '372.725', '0.07'),
  createData('2023/5/31', '430.555', '449.977', '0.07'),
  createData('2023/6/1', '462.85', '355.261', '0.07'),
  createData('2023/6/2', '465.61', '271.469', '0.07'),
  createData('2023/6/3', '464.58', '285.959', '0.07'),
  createData('2023/6/4', '480.22', '307.829', '0.07'),
  createData('2023/6/5', '547.447', '466.661', '0.07'),
  createData('2023/6/6', '527.638', '409.712', '0.07'),
  createData('2023/6/7', '485.834', '288.246', '0.07'),
  createData('2023/6/8', '435.586', '212.609', '0.07'),
  createData('2023/6/9', '386.92', '155.370', '0.07'),
  createData('2023/6/10', '352.261', '127.079', '0.07'),
  createData('2023/6/11', '326.106', '112.266', '0.07'),
  createData('2023/6/12', '301.579', '100.577', '0.07'),
  createData('2023/6/13', '279.477', '94.335', '0.07'),
  createData('2023/6/14', '256.261', '86.083', '0.07'),
  createData('2023/6/15', '234.886', '77.798', '0.07'),
  createData('2023/6/16', '217.295', '67.520', '0.07'),
  createData('2023/6/17', '203.631', '59.828', '0.07'),
  createData('2023/6/18', '193.352', '54.880', '0.07'),
  createData('2023/6/19', '185.739', '51.885', '0.07'),
  createData('2023/6/20', '180.12', '50.095', '0.07'),
  createData('2023/6/21', '175.943', '48.996', '0.07'),
  createData('2023/6/22', '172.784', '48.277', '0.07'),
  createData('2023/6/23', '170.33', '47.761', '0.07'),
  createData('2023/6/24', '172.83', '47.350', '0.07'),
  createData('2023/6/25', '183.419', '56.285', '0.07'),
  createData('2023/6/26', '194.048', '61.296', '0.07'),
  createData('2023/6/27', '206.195', '89.056', '0.07'),
  createData('2023/6/28', '216.133', '88.871', '0.07'),
  createData('2023/6/29', '226.762', '124.933', '0.07'),
  createData('2023/6/30', '243.735', '127.857', '0.07'),
  createData('2023/7/1', '249.353', '115.175', '0.07'),
  createData('2023/7/2', '245.559', '100.131', '0.07'),
  createData('2023/7/3', '249.325', '129.659', '0.07'),
  createData('2023/7/4', '252.186', '116.970', '0.07'),
  createData('2023/7/5', '305.986', '260.394', '0.07'),
  createData('2023/7/6', '319.08', '223.302', '0.07'),
  createData('2023/7/7', '345.601', '285.882', '0.07'),
  createData('2023/7/8', '345.538', '271.254', '0.07'),
  createData('2023/7/9', '355.872', '282.606', '0.07'),
  createData('2023/7/10', '391.12', '242.508', '0.07'),
  createData('2023/7/11', '413.233', '231.749', '0.07'),
  createData('2023/7/12', '395.251', '184.411', '0.07'),
  createData('2023/7/13', '363.526', '145.283', '0.07'),
  createData('2023/7/14', '395.096', '152.355', '0.07'),
  createData('2023/7/15', '415.292', '182.211', '0.07'),
  createData('2023/7/16', '395.59', '155.399', '0.07'),
  createData('2023/7/17', '362.892', '131.282', '0.07'),
  createData('2023/7/18', '326.617', '111.556', '0.07'),
  createData('2023/7/19', '290.051', '95.534', '0.07'),
  createData('2023/7/20', '256.22', '85.473', '0.07'),
  createData('2023/7/21', '231.259', '81.321', '0.07'),
  createData('2023/7/22', '214.005', '78.921', '0.07'),
  createData('2023/7/23', '197.898', '73.301', '0.07'),
  createData('2023/7/24', '184.521', '64.677', '0.07'),
  createData('2023/7/25', '174.052', '57.715', '0.07'),
  createData('2023/7/26', '166.115', '53.093', '0.07'),
  createData('2023/7/27', '160.189', '50.247', '0.07'),
  createData('2023/7/28', '157.619', '48.529', '0.07'),
  createData('2023/7/29', '155.8', '49.778', '0.07'),
  createData('2023/7/30', '153.301', '48.852', '0.07'),
  createData('2023/7/31', '150.798', '52.364', '0.07'),
  createData('2023/8/1', '148.544', '51.856', '0.07'),
  createData('2023/8/2', '146.595', '49.683', '0.07'),
  createData('2023/8/3', '150.104', '55.623', '0.07'),
  createData('2023/8/4', '150.71', '57.967', '0.07'),
  createData('2023/8/5', '149.088', '55.099', '0.07'),
  createData('2023/8/6', '146.762', '51.123', '0.07'),
  createData('2023/8/7', '144.405', '48.039', '0.07'),
  createData('2023/8/8', '149.473', '62.783', '0.07'),
  createData('2023/8/9', '157.115', '87.692', '0.07'),
  createData('2023/8/10', '199.166', '143.363', '0.07'),
  createData('2023/8/11', '218.09', '144.456', '0.07'),
  createData('2023/8/12', '223.19', '124.563', '0.07'),
  createData('2023/8/13', '220.931', '107.790', '0.07'),
  createData('2023/8/14', '248.476', '95.462', '0.07'),
  createData('2023/8/15', '255.351', '89.651', '0.07'),
  createData('2023/8/16', '250.242', '81.334', '0.07'),
  createData('2023/8/17', '262.521', '97.368', '0.07'),
  createData('2023/8/18', '259.807', '91.461', '0.07'),
  createData('2023/8/19', '252.61', '92.396', '0.07'),
  createData('2023/8/20', '250.388', '107.379', '0.07'),
  createData('2023/8/21', '248.568', '110.207', '0.07'),
  createData('2023/8/22', '240.067', '96.733', '0.07'),
  createData('2023/8/23', '225.396', '81.572', '0.07'),
  createData('2023/8/24', '209.436', '67.723', '0.07'),
  createData('2023/8/25', '190.664', '57.164', '0.07'),
  createData('2023/8/26', '173.19', '50.282', '0.07'),
  createData('2023/8/27', '159.439', '46.091', '0.07'),
  createData('2023/8/28', '161.706', '53.281', '0.07'),
  createData('2023/8/29', '170.355', '58.233', '0.07'),
  createData('2023/8/30', '232.464', '60.690', '0.07'),
  createData('2023/8/31', '306.05', '190.867', '0.07'),
  createData('2023/9/1', '316.955', '168.494', '0.07'),
  createData('2023/9/2', '301.441', '135.361', '0.07'),
  createData('2023/9/3', '272.988', '104.460', '0.07'),
  createData('2023/9/4', '249.147', '86.157', '0.07'),
  createData('2023/9/5', '231.098', '77.071', '0.07'),
  createData('2023/9/6', '255.097', '129.470', '0.07'),
  createData('2023/9/7', '295.35', '174.042', '0.07'),
  createData('2023/9/8', '332.94', '184.535', '0.07'),
  createData('2023/9/9', '425.119', '267.912', '0.07'),
  createData('2023/9/10', '424.825', '217.374', '0.07'),
  createData('2023/9/11', '435.409', '190.236', '0.07'),
  createData('2023/9/12', '543.437', '271.192', '0.07'),
  createData('2023/9/13', '567.462', '279.054', '0.07'),
  createData('2023/9/14', '557.586', '276.231', '0.07'),
  createData('2023/9/15', '525.346', '229.993', '0.07'),
  createData('2023/9/16', '486.436', '181.529', '0.07'),
  createData('2023/9/17', '464.848', '172.144', '0.07'),
  createData('2023/9/18', '420.446', '139.388', '0.07'),
  createData('2023/9/19', '370.152', '109.944', '0.07'),
  createData('2023/9/20', '319.429', '85.450', '0.07'),
  createData('2023/9/21', '274.473', '68.833', '0.07'),
  createData('2023/9/22', '238.249', '58.515', '0.07'),
  createData('2023/9/23', '210.577', '52.383', '0.07'),
  createData('2023/9/24', '190.115', '48.804', '0.07'),
  createData('2023/9/25', '175.286', '46.703', '0.07'),
  createData('2023/9/26', '164.657', '45.435', '0.07'),
  createData('2023/9/27', '159.402', '47.424', '0.07'),
  createData('2023/9/28', '154.843', '48.092', '0.07'),
  createData('2023/9/29', '150.722', '46.698', '0.07'),
  createData('2023/9/30', '147.261', '45.222', '0.07'),
  createData('2023/10/1', '153.101', '47.088', '0.07'),
  createData('2023/10/2', '159.662', '46.976', '0.07'),
  createData('2023/10/3', '161.118', '45.430', '0.07'),
  createData('2023/10/4', '165.488', '43.978', '0.07'),
  createData('2023/10/5', '174.228', '49.508', '0.07'),
  createData('2023/10/6', '181.539', '55.600', '0.07'),
  createData('2023/10/7', '182.395', '57.279', '0.07'),
  createData('2023/10/8', '195.27', '124.525', '0.07'),
  createData('2023/10/9', '205.531', '147.203', '0.07'),
  createData('2023/10/10', '210.679', '128.613', '0.07'),
  createData('2023/10/11', '213.525', '127.875', '0.07'),
  createData('2023/10/12', '217.019', '135.871', '0.07'),
  createData('2023/10/13', '218.919', '139.432', '0.07'),
  createData('2023/10/14', '234.644', '135.604', '0.07'),
  createData('2023/10/15', '322.516', '204.058', '0.07'),
  createData('2023/10/16', '389.183', '265.939', '0.07'),
  createData('2023/10/17', '408.174', '247.411', '0.07'),
  createData('2023/10/18', '387.184', '195.461', '0.07'),
  createData('2023/10/19', '351.56', '149.464', '0.07'),
  createData('2023/10/20', '319.819', '113.030', '0.07'),
  createData('2023/10/21', '291.838', '88.250', '0.07'),
  createData('2023/10/22', '267.636', '70.546', '0.07'),
  createData('2023/10/23', '248.76', '61.220', '0.07'),
  createData('2023/10/24', '239.643', '58.619', '0.07'),
  createData('2023/10/25', '232.045', '58.850', '0.07'),
  createData('2023/10/26', '224.962', '61.762', '0.07'),
  createData('2023/10/27', '214.351', '61.954', '0.07'),
  createData('2023/10/28', '197.973', '58.046', '0.07'),
  createData('2023/10/29', '180.169', '52.617', '0.07'),
  createData('2023/10/30', '164.458', '48.372', '0.07'),
  createData('2023/10/31', '151.82', '45.566', '0.07'),
  createData('2023/11/1', '142.147', '43.818', '0.07'),
  createData('2023/11/2', '139.026', '42.731', '0.07'),
  createData('2023/11/3', '134.612', '42.028', '0.07'),
  createData('2023/11/4', '133.789', '43.326', '0.07'),
  createData('2023/11/5', '142.567', '51.878', '0.07'),
  createData('2023/11/6', '156.075', '57.253', '0.07'),
  createData('2023/11/7', '167.729', '60.067', '0.07'),
  createData('2023/11/8', '186.044', '97.605', '0.07'),
  createData('2023/11/9', '197.057', '96.198', '0.07'),
  createData('2023/11/10', '219.166', '103.603', '0.07'),
  createData('2023/11/11', '227.33', '93.433', '0.07'),
  createData('2023/11/12', '230.062', '93.283', '0.07'),
  createData('2023/11/13', '225.576', '83.112', '0.07'),
  createData('2023/11/14', '232.258', '102.970', '0.07'),
  createData('2023/11/15', '231.707', '92.907', '0.07'),
  createData('2023/11/16', '223.665', '79.207', '0.07'),
  createData('2023/11/17', '219.388', '68.908', '0.07'),
  createData('2023/11/18', '214.672', '59.842', '0.07'),
  createData('2023/11/19', '240.402', '56.696', '0.07'),
  createData('2023/11/20', '270.319', '80.058', '0.07'),
  createData('2023/11/21', '272.517', '78.509', '0.07'),
  createData('2023/11/22', '343.616', '141.858', '0.07'),
  createData('2023/11/23', '344.049', '125.431', '0.07'),
  createData('2023/11/24', '320.627', '104.892', '0.07'),
  createData('2023/11/25', '289.662', '86.753', '0.07'),
  createData('2023/11/26', '330.596', '157.050', '0.07'),
  createData('2023/11/27', '341.454', '167.800', '0.07'),
  createData('2023/11/28', '327.426', '139.672', '0.07'),
  createData('2023/11/29', '308.079', '115.687', '0.07'),
  createData('2023/11/30', '289.233', '99.512', '0.07'),
  createData('2023/12/1', '283.439', '85.891', '0.07'),
  createData('2023/12/2', '311.683', '75.772', '0.07'),
  createData('2023/12/3', '305.283', '66.227', '0.07'),
  createData('2023/12/4', '292.111', '59.255', '0.07'),
  createData('2023/12/5', '277.722', '60.653', '0.07'),
  createData('2023/12/6', '269.576', '76.361', '0.07'),
  createData('2023/12/7', '258.602', '75.760', '0.07'),
  createData('2023/12/8', '258.793', '74.041', '0.07'),
  createData('2023/12/9', '249.33', '68.418', '0.07'),
  createData('2023/12/10', '236.822', '62.052', '0.07'),
  createData('2023/12/11', '224.457', '56.288', '0.07'),
  createData('2023/12/12', '210.559', '49.871', '0.07'),
  createData('2023/12/13', '202.875', '44.600', '0.07'),
  createData('2023/12/14', '202.082', '41.075', '0.07'),
  createData('2023/12/15', '204.978', '41.007', '0.07'),
  createData('2023/12/16', '214.594', '40.668', '0.07'),
  createData('2023/12/17', '243.727', '45.474', '0.07'),
  createData('2023/12/18', '284.224', '61.727', '0.07'),
  createData('2023/12/19', '426.116', '123.431', '0.07'),
  createData('2023/12/20', '506.116', '174.648', '0.07'),
  createData('2023/12/21', '511.485', '157.074', '0.07'),
  createData('2023/12/22', '519.639', '227.475', '0.07'),
  createData('2023/12/23', '479.023', '188.553', '0.07'),
  createData('2023/12/24', '437.539', '198.419', '0.07'),
  createData('2023/12/25', '433.794', '215.067', '0.07'),
  createData('2023/12/26', '401.536', '178.006', '0.07'),
  createData('2023/12/27', '374.489', '236.937', '0.07'),
  createData('2023/12/28', '354.229', '240.994', '0.07'),
  createData('2023/12/29', '345.048', '192.741', '0.07'),
  createData('2023/12/30', '331.997', '166.132', '0.07'),
  createData('2023/12/31', '311.642', '132.751', '0.07'),
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {
  return (
    <React.Fragment>
      <Title>Water Availability Table</Title>
      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Date</TableCell>
            {/* <TableCell align="center" sx={{fontWeight: 'bold'}}>Q at Tenom (m<sup>3</sup>/s)</TableCell> */}
            <TableCell align="center" sx={{fontWeight: 'bold'}}>LRA Kemabong Abstraction (m<sup>3</sup>/s)</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Q (m<sup>3</sup>/s)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center">{row.date}</TableCell>
              {/* <TableCell align="center">{row.tenomq}</TableCell> */}
              <TableCell align="center">{row.kemambongq}</TableCell>
              <TableCell align="center">{row.q}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
