import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';

let id = -1
// Generate Order Data
function createData(wtp, year18, year17) {
  id = id + 1;
  return { id, wtp, year18, year17};
}

const rows = [
  createData('1. Sg Batu WTP', '44.11 ', '42.61'),
  createData('2. Sg Kepong WTP', '0.89 ', '0.95'),
  createData('3. Sg Gombak WTP', '12.02 ', '13.14'),
  createData('4. Sg Rumput WTP', '0.43 ', '0.43'),
  createData('5. Wangsa Maju WTP', '16.83 ', '15.13'),
  createData('6. Bukit Nanas WTP', '43.54 ', '46.05'),
  createData('7. Ampang Intake WTP', '6.94 ', '6.69'),
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {
  return (
    <React.Fragment>
      <Title>Surface Water Liabilities</Title>
      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow sx={{backgroundColor: '#ebebeb'}}>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Allocation Account Balance</TableCell>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold'}}>31 Dec 2018</TableCell>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold'}}>31 Dec 2017</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>&nbsp;Water Treatment Plants</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell >&nbsp;&nbsp;&nbsp;{row.wtp}</TableCell>
              <TableCell>{row.year18}</TableCell>
            <TableCell></TableCell>
              <TableCell>{row.year17}</TableCell>
            <TableCell></TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>Total Allocation Balance (L<sub>sws</sub>)</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>125</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>125</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow sx={{backgroundColor: '#ebebeb'}}>
            <TableCell sx={{fontStyle: 'italic'}}>Change in Allocation Account Balance</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontStyle: 'italic'}}>(0.23)</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
