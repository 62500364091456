import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';

let id = -1
// Generate Order Data
// function createData(wtp, source, capacity) {
//   id = id + 1;
//   return { id, wtp, source, capacity};
// }

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {
  return (
    <React.Fragment>
      <Title>Surface Water Assets</Title>
      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow sx={{backgroundColor: '#ebebeb'}}>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Surface Water Storage</TableCell>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold'}}>31 Dec 2018</TableCell>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold'}}>31 Dec 2017</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>&nbsp;Dam</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>&nbsp;&nbsp;&nbsp;1. Batu Dam</TableCell>
            <TableCell>36.60</TableCell>
            <TableCell></TableCell>
            <TableCell>31.45</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>&nbsp;&nbsp;&nbsp;2. Klang Gates Dam</TableCell>
            <TableCell>31.10</TableCell>
            <TableCell></TableCell>
            <TableCell>30.94</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>Total Surface Water Storage (A<sub>sws</sub>)</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>68</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>62</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow sx={{backgroundColor: '#ebebeb'}}>
            <TableCell sx={{fontStyle: 'italic'}}>Change in Water Surface Storage</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontStyle: 'italic'}}>5</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
